import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import { StyledLabelForWebsite } from 'pages/WebsiteSettings/Rewards/styles';
import { DropOptionContainer, DropTitle } from 'pages/WebsiteSettings/DropOfOptions/styles';


const DropOfOptions = ({ model, handleOptionsChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <DropTitle>{t('Drop-off options')}</DropTitle>
      <DropOptionContainer>
        <FormGroup
          checked={model.deliveryBy[0]?.dropoffVerification?.meetAtDoor?.enabled}
          name={`deliveryBy[0]?.dropoffVerification.meetAtDoor.enabled`}
        >
          <FormControlLabel
            control={<Switch
              disabled={true}
              checked={model.deliveryBy[0]?.dropoffVerification?.meetAtDoor?.enabled} />}
            label={<StyledLabelForWebsite>{t('Enable "Hand it to me"')}</StyledLabelForWebsite>}
          />
        </FormGroup>
        <FormGroup
          checked={model.deliveryBy[0]?.dropoffVerification?.leaveAtDoor?.enabled}
          name={`deliveryBy[0]?.dropoffVerification.leaveAtDoor.enabled`}
          onChange={handleOptionsChange}
        >
          <FormControlLabel
            control={<Switch checked={model.deliveryBy[0]?.dropoffVerification?.leaveAtDoor?.enabled} />}
            label={<StyledLabelForWebsite>{t('Enable "Leave it at my door"')}</StyledLabelForWebsite>}
          />
        </FormGroup>
      </DropOptionContainer>
    </>
  )
}

export default memo(DropOfOptions);