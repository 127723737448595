import { memo } from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';


const StrategyList = ({ strategyList, disabled }) => {
  const { t } = useTranslation();

  return (
    <CustomTextInput
      sx={{ width: '100%', mt: 2, ml: 2, maxWidth: '336px' }}
      select={true}
      disabled={disabled}
      name="strategyId"
      label={<LabelRequired>{t('Strategy')}</LabelRequired>}
    >
      {strategyList.map((option) => (
        <MenuItem key={option._id} value={option._id}>
          {option.title}
        </MenuItem>
      ))}
    </CustomTextInput>
  );
};
export default memo(StrategyList);
