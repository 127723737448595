import { useMemo } from 'react';
import usePermission from './permission';
import { useSelector } from 'react-redux';
import * as all from 'constants/permissions';
import { WIX_FREE_PLAN_VALUE } from 'constants/config';
import { PROVIDER_LIST } from 'components/NewHome/constants/constatnts';
import { selectCurrentBusiness } from 'components/Providers/ProviderList';

const selectProviders = (state) => state.providers?.items;

const useWixFreePlan = () => {

  const checkPermission = usePermission();
  const items = useSelector(selectProviders);
  const currentBusiness = useSelector(selectCurrentBusiness);

  const upgradeUrl = useMemo(() => {
    const findWix = items.find((item) => item?.srv === PROVIDER_LIST.wix);
    const instanceId = currentBusiness?.metadata?.wixAppInstanceId || findWix?.storeId;
    if (instanceId) {
      return `${process.env.REACT_APP_WIX_UPGRADE_URL}/${process.env.REACT_APP_WIX_APP_ID}?appInstanceId=${instanceId}`;
    }
  },[currentBusiness, items])

  const wixFreePlan = useMemo(() => {
    return currentBusiness?.plan?.value === WIX_FREE_PLAN_VALUE && !checkPermission(all['SUPER']);
  },[currentBusiness])

  return {
    wixFreePlan,
    upgradeUrl
  }
}

export default useWixFreePlan;