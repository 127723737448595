import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Divider,
  FormLabel,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { PAY_BASIS_ENUM } from 'pages/TableOrdering/hooks/useTableOrderingJobs';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';

const CreateOrEditForm = ({
  currentPayBasis,
  handleChangePayBasis,
  // currentTipped,
  // handleChangeTipped,
  // currentCashier,
  // handleChangeCashier,
  // currentDriver,
  // handleChangeDriver,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                name="title"
                label={<LabelRequired> {t('table_ordering.job_title')}</LabelRequired>}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_RIGHT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                name="code"
                label={t('table_ordering.job_code')}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12} lg={12} xl={12} sx={{ pt: '0px !important' }}>
            <Divider />
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px', mt: 1.5 }}
                name="defaultPay"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label={t('table_ordering.default_pay')}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12} lg={12} xl={12}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" sx={{ color: '#49454F', fontSize: '16px' }}>
                  {<LabelRequired>{t('table_ordering.pay_basis')}</LabelRequired>}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={currentPayBasis}
                  onChange={handleChangePayBasis}
                >
                  <FormControlLabel
                    value={PAY_BASIS_ENUM.HOURLY}
                    control={<Radio />}
                    label={t('table_ordering.job_salary_value_hourly')}
                  />
                  <FormControlLabel
                    value={PAY_BASIS_ENUM.SALARY}
                    control={<Radio />}
                    label={t('table_ordering.job_salary_value_salary')}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          {/*<Grid item md={12} xs={12} lg={12} xl={12} sx={{ pt: '0px !important' }}>*/}
          {/*  <Divider />*/}
          {/*</Grid>*/}
          {/*<Grid item md={12} xs={12} lg={12} xl={12}>*/}
          {/*  <Box sx={PADDING_MODAL_ACTION_LEFT}>*/}
          {/*    <FormControl>*/}
          {/*      <FormLabel id="demo-radio-buttons-group-label" sx={{ color: '#49454F', fontSize: '16px' }}>*/}
          {/*        {t('table_ordering.tipped')}*/}
          {/*      </FormLabel>*/}
          {/*      <RadioGroup*/}
          {/*        aria-labelledby="demo-radio-buttons-group-label"*/}
          {/*        defaultValue=""*/}
          {/*        name="radio-buttons-group"*/}
          {/*        value={currentTipped}*/}
          {/*        onChange={handleChangeTipped}*/}
          {/*      >*/}
          {/*        <FormControlLabel*/}
          {/*          value={TIPPED_ENUM.YES}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.tipped_radio_value_yes')}*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*          value={TIPPED_ENUM.NO}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.tipped_radio_value_no')}*/}
          {/*        />*/}
          {/*      </RadioGroup>*/}
          {/*    </FormControl>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          {/*<Grid item md={12} xs={12} lg={12} xl={12}>*/}
          {/*  <Box sx={PADDING_MODAL_ACTION_LEFT}>*/}
          {/*    <FormControl>*/}
          {/*      <FormLabel id="demo-radio-buttons-group-label" sx={{ color: '#49454F', fontSize: '16px' }}>*/}
          {/*        {t('table_ordering.cashier')}*/}
          {/*      </FormLabel>*/}
          {/*      <RadioGroup*/}
          {/*        aria-labelledby="demo-radio-buttons-group-label"*/}
          {/*        defaultValue=""*/}
          {/*        name="radio-buttons-group"*/}
          {/*        value={currentCashier}*/}
          {/*        onChange={handleChangeCashier}*/}
          {/*      >*/}
          {/*        <FormControlLabel*/}
          {/*          value={CASHIER_ENUM.YES}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.cash_driver_yes')}*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*          value={CASHIER_ENUM.NO}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.cash_driver_no')}*/}
          {/*        />*/}
          {/*      </RadioGroup>*/}
          {/*    </FormControl>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          {/*<Grid item md={12} xs={12} lg={12} xl={12}>*/}
          {/*  <Box sx={PADDING_MODAL_ACTION_LEFT}>*/}
          {/*    <FormControl>*/}
          {/*      <FormLabel id="demo-radio-buttons-group-label" sx={{ color: '#49454F', fontSize: '16px' }}>*/}
          {/*        {t('table_ordering.driver')}*/}
          {/*      </FormLabel>*/}
          {/*      <RadioGroup*/}
          {/*        aria-labelledby="demo-radio-buttons-group-label"*/}
          {/*        defaultValue=""*/}
          {/*        name="radio-buttons-group"*/}
          {/*        value={currentDriver}*/}
          {/*        onChange={handleChangeDriver}*/}
          {/*      >*/}
          {/*        <FormControlLabel*/}
          {/*          value={DRIVER_ENUM.YES}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.cash_driver_yes')}*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*          value={DRIVER_ENUM.NO}*/}
          {/*          control={<Radio />}*/}
          {/*          label={t('table_ordering.cash_driver_no')}*/}
          {/*        />*/}
          {/*      </RadioGroup>*/}
          {/*    </FormControl>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </>
  );
};
export default CreateOrEditForm;
