import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CancelButton, SaveButton } from 'pages/TableOrdering/styles';
import { CustomDialogTitle } from 'pages/Auth/TwoFactor/TowFactorDialog';
import { Dialog, Box, DialogContent, DialogActions } from '@mui/material';
import useChangePaymentSystem, {
  paymentSystems
} from 'pages/WebsiteSettings/PaymentConfiguration/hooks/useChangePaymentSystem';
import MenuItem from '@mui/material/MenuItem';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { TowFactorContainerText } from 'pages/Auth/TwoFactor/styles';

const ChangePaymentSystemDialog = ({ open, handleClose, websiteFormMethods }) => {
  const { t } = useTranslation();
  const {
    paymentSystem,
    handleSubmit,
    loading,
  } = useChangePaymentSystem(open, handleClose, websiteFormMethods)

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperProps={{ sx: { width: '454px', maxWidth: '100%', background: '#FAFAFA' } }}
    >
      <FormProvider {...paymentSystem}>
        <form onSubmit={paymentSystem.handleSubmit(handleSubmit)}>
          <Box>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t('Add/Edit Payment System')}
            </CustomDialogTitle>
            <DialogContent sx={{ width: '100%', padding: 0, cursor: 'auto' }}>
              <TowFactorContainerText sx={{ textAlign: 'left' }}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  select={true}
                  name="paymentSystem"
                  label={t('Payment System')}
                >
                  {paymentSystems.map((system) => {
                    return (
                      <MenuItem key={system.value} value={system.value}>
                        {system.label}
                      </MenuItem>
                    )
                  })}
                </CustomTextInput>
              </TowFactorContainerText>
              <TowFactorContainerText sx={{ mb: 2 }}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  name="accountId"
                  label={t('Account ID')}
                />
              </TowFactorContainerText>
            </DialogContent>
            <DialogActions sx={{ borderTop: '1px solid #E7E7E7' }}>
              <CancelButton variant="text" onClick={handleClose}>
                {t('Cancel')}
              </CancelButton>
              <SaveButton
                sx={{ mr: 2 }}
                variant="contained"
                type="submit"
                loading={loading}
                disabled={loading || !paymentSystem.formState.isValid}
              >
                {t('Save')}
              </SaveButton>
            </DialogActions>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
)
}

export default ChangePaymentSystemDialog