import { useEffect, useMemo, useState } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleSocketsEventsByType } from 'redux/sockets';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import logo from 'assets/images/logo.png';
import AddEditSchedule from 'pages/Schedule/addEditSchedule';
import { getBusinessConfig, getProviders, getProvidersStatuses } from 'redux/actions/providers';
import { updateStopResumeOrders } from 'redux/actions/providers';
import Schedule from 'pages/Schedule/Schedule';
import Providers from 'pages/Providers/index';
import Settings from 'pages/Settings';
import WebsiteSettings from 'pages/WebsiteSettings';
import Orders from 'pages/Orders';
import Help from 'pages/Help';
import Menu from 'pages/MenuNew/index';
import Reports from 'pages/Report';
import Sidebar from 'components/Sidebar';
import Preloader from 'components/Preloader';
import ProtectedRoute from 'components/ProtectedRoute';
import { useSocket } from 'providers/Socket';
import BrandSignUp from 'pages/Auth/BrandSignUp/index';
import ResumePause from 'components/PauseResume/index';
import OverlayOrders from 'components/Order/OverlayOrders';
import Header from 'components/Header/index';
import { useIsWidthDown } from 'hooks/mediaQuery';
import { RESET_MODIFIED_ORDERS, ORDERS_EMPTY, RESET_CANCELED_ORDERS } from 'redux/actions/types';
import OrdersHistory from 'pages/OrderHistory';
import AddLocation from 'pages/AddLocation';
import PaymentNotificationDialog from 'components/PaymentNotificationModal';
import { getPaymentInfo } from 'redux/actions/paymentNotification';
import InvitationLocation from 'pages/InvitationLocation/InvitationLocation';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import usePermissionPlan from 'hooks/permissionPlan';
import TableOrdering from 'pages/TableOrdering';
import { SkeletonWrap } from 'components/Settings/Skeleton/SkeletonSidebarList';
import { companyTypesEnum } from 'constants/companyTypes';
import OnBoarding from 'components/OnBoarding/OnBoarding';
import ChargeBack from 'pages/Chargeback';
import NewHome from 'components/NewHome';
import useQuery from 'hooks/useQuery';
import HubSpotChatPage from 'pages/HubSpotChatPage';
import ResumePauseProviderIndex from 'components/PauseResume/PauseResumeProviderIndex';
import UpdatePlanWix from 'components/Wix/UpdatePlanWix';
import useWixFreePlan from 'hooks/useWixFreePlan';

const drawerWidth = 240;

// Styled Components
const MainBlock = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    overflowY: 'auto',
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

function Main() {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();

  const { isTypeSingle } = isPermissionWithCompanyType();
  const mdDown = useIsWidthDown('md');
  const { subscribe, unsubscribe, eventEmit } = useSocket();
  const [overlay, setOverlay] = useState(false);
  const [joined, setJoined] = useState(false);
  const query = useQuery();
  const mode = query.get('mode');
  const entityType = query.get('entityType');

  const {
    data,
    newOrders,
    modifiedOrders,
    lastUpdatedId,
    lastCanceledId,
    canceledOrders,
    providers,
  } = useSelector(
    ({
       user: { data, loading },
       orders: { newOrders, modifiedOrders, lastUpdatedId, lastCanceledId, canceledOrders },
       providers,
     }) => ({
      data,
      loading,
      newOrders,
      modifiedOrders,
      canceledOrders,
      lastUpdatedId,
      lastCanceledId,
      providers,
    })
  );
  const { wixFreePlan } = useWixFreePlan();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { items, stopResumeOrders } = useSelector(({ providers }) => providers);
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const providerCheck =
    window.location.pathname.includes('/providers') &&
    checkPermissionPlan(all['APP_BUNDLE']) &&
    checkPermission(all['PROVIDERS_VIEW']) &&
    isTypeSingle
      ? !providers?.items?.length
      : 0;
  const skeletonLoading = !currentBusiness?.roles || !data || providerCheck;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (mode === 'create' && entityType === 'modifier') {
      window.scrollTo(0, 0);
    }
  }, [mode, entityType]);

  useEffect(() => {
    setOpen(!mdDown);
  }, [mdDown]);

  useEffect(() => {
    // if (checkPermission(all['PROVIDERS_VIEW'])) {
      dispatch(getProviders());
    // }
    if (currentBusiness?.type === companyTypesEnum.SINGLE) {
      dispatch(getProvidersStatuses());
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (
      currentBusiness?._id &&
      currentBusiness?.roles?.name &&
      currentBusiness?.roles?.name !== 'super'
    ) {
      dispatch(getPaymentInfo(currentBusiness._id));
    }
  }, [currentBusiness]);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      handleSocketsEventsByType(data);
    };
    subscribe('event-bus', handleSocketEvent);

    return () => {
      unsubscribe('event-bus', handleSocketEvent);
    };
  }, [subscribe, unsubscribe]);

  useEffect(() => {
    if (data) {
      setJoined(true);
      if (!joined) {
        eventEmit('join', { id: data._id });
      }
    }
  }, [data, joined, eventEmit]);

  useEffect(() => {
    setOverlay(newOrders.length || modifiedOrders.length || canceledOrders.length);
  }, [newOrders.length, modifiedOrders.length, canceledOrders.length]);

  // Removed useLayoutEffect for dynamic margin calculation

  useEffect(() => {
    dispatch(getBusinessConfig());
  }, []);

  const openOrders = () => {
    history.push({
      pathname: `/orders${newOrders.length || modifiedOrders.length || canceledOrders.length
        ? `/${lastCanceledId || lastUpdatedId}`
        : ''
      }`,
      search: `?period=today&filter=all`,
    });
    setOverlay(false);
    dispatch({ type: ORDERS_EMPTY });
    dispatch({ type: RESET_MODIFIED_ORDERS });
    dispatch({ type: RESET_CANCELED_ORDERS });
  };

  if (location.pathname.includes('/addBrand')) {
    return <BrandSignUp data={data} />;
  }
  if (location.pathname.includes('/addLocation')) {
    return <AddLocation data={data} />;
  }
  if (location.pathname.includes('/invitationLocation')) {
    return <InvitationLocation />;
  }
  if (location.pathname.includes('/help/chat')) {
    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Switch>
          <Route path="/help/chat/:businessId" component={HubSpotChatPage} />
        </Switch>
      </Box>
    );
  }

  const getBusinessTitle = (business) => {
    if (business) {
      const { title, companyName, disabled, locationInfo } = business;
      const name = title || companyName || '';
      const location = locationInfo?.locationName ? ` - ${locationInfo.locationName}` : '';
      const status = disabled ? ' (Disabled)' : '';
      return `${name}${location}${status}`;
    }
    return '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80vh',
        gap: '0px', // 30px gap between Header and Content
      }}
    >
      {/* Header */}
      <Header handleDrawerOpen={handleDrawerOpen} open={open} drawerWidth={drawerWidth} sx={{ boxSizing: 'border-box' }} />

      {/* Main Content Area */}
      < Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        {/* Drawer */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#3c4351',
            },
            '& .MuiListItemIcon-root': {
              color: (theme) => theme.palette.common.white,
            },
            '& .MuiListItemText-root': {
              color: (theme) => theme.palette.common.white,
            },
            '& .MuiButtonBase-root': {
              color: (theme) => theme.palette.common.white,
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >

          <Box id="drawerHeader" sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            boxSizing: 'border-box',
            height: "70px",
            padding: "8px",
            borderBottom: "1px solid #646973"
          }}>
            <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
              {skeletonLoading ? (
                <Box sx={{ width: '24px', height: '40px', display: 'flex', alignItems: 'center' }}>
                  <SkeletonWrap variant="circular" width={24} height={24} />
                </Box>
              ) : (
                <IconButton id="mainChevron" disableRipple onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ListItem to="/" component={Link}>
                  <ListItemIcon sx={{ width: '40px' }}>
                    {skeletonLoading ? (
                      <SkeletonWrap variant="circular" width={40} height={40} />
                    ) : (
                      <img src={logo} alt="orders.co" style={{ height: "40px", width: "40px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: (theme) => theme.palette.common.white, }}
                    primary={
                      <Box>
                        {skeletonLoading ? (
                          <SkeletonWrap animation="wave" height={20} width="64px" />
                        ) : (
                          <Typography>Orders.co</Typography>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              </Box>
            </Box>
          </Box>
          <Box sx={{ borderBottom: "1px solid #646973", height: "48px" }}>
            {skeletonLoading ? (
              <SkeletonWrap animation="wave" height={30} width="77px" />
            ) : (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#bcbec3',
                  px: 2,
                  py: 2,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', // ✅ Corrected from 'ellipsis' to 'hidden'
                  lineHeight: '16px',
                }}

              >
                {getBusinessTitle(currentBusiness)}
              </Typography>
            )}
          </Box>
          <Sidebar />
        </Drawer>

        {/* MainBlock */}
        <MainBlock open={open}>
          <Box>
            {skeletonLoading || !providers.items ? (
              <Preloader overlay className="app-loader" />
            ) : (
              <Box>
                <Switch>
                  {data?.roles?.name !== 'user' && (
                    <ProtectedRoute
                      exact permission="HOME_REPORT_FILTER" path="/"
                      component={wixFreePlan ? UpdatePlanWix : NewHome} />
                  )}
                  <Route exact path="/help" component={wixFreePlan ? UpdatePlanWix : Help} />
                  <Route exact path="/orders/:current?" component={wixFreePlan ? UpdatePlanWix : Orders} />
                  <Route exact path="/onboarding" component={wixFreePlan ? UpdatePlanWix : OnBoarding} />
                  <Route exact path="/history/:current?" component={wixFreePlan ? UpdatePlanWix : OrdersHistory} />
                  <Route exact path="/schedule" component={wixFreePlan ? UpdatePlanWix : Schedule} />
                  <ProtectedRoute
                    exact path="/schedule/new/:id?"
                    component={wixFreePlan ? UpdatePlanWix : AddEditSchedule} />
                  <Route path="/settings" component={wixFreePlan ? UpdatePlanWix : Settings} />
                  <ProtectedRoute path="/website" component={wixFreePlan ? UpdatePlanWix : WebsiteSettings} />
                  <ProtectedRoute
                    permission="HOME_REPORT_FILTER" path="/reports"
                    component={wixFreePlan ? UpdatePlanWix : Reports} />
                  <Route path="/menu" component={wixFreePlan ? UpdatePlanWix : Menu} />
                  <Route path="/chargeback" component={wixFreePlan ? UpdatePlanWix : ChargeBack} />
                  <Route path="/table-ordering" component={wixFreePlan ? UpdatePlanWix : TableOrdering} />
                  <ProtectedRoute
                    path="/providers"
                    component={wixFreePlan ? UpdatePlanWix : Providers} permission="PROVIDERS_VIEW" />
                  <Redirect from="/payment-success" to="/website/main" />
                  <Redirect to={data?.roles?.name !== 'user' ? '/' : '/orders'} />
                </Switch>
              </Box>
            )}
          </Box>
          <PaymentNotificationDialog />
          {overlay ? (
            <OverlayOrders
              openOrders={openOrders}
              setOverlay={setOverlay}
              newOrders={newOrders}
              modifiedOrders={modifiedOrders}
              canceledOrders={canceledOrders}
            />
          ) : (
            <></>
          )}
          {isTypeSingle && stopResumeOrders?.provider === 'all' ? (
            <ResumePause
              providers={items}
              updateStopResumeOrders={updateStopResumeOrders}
              stopResumeOrders={stopResumeOrders}
            />
          ) : (
            <>
              {isTypeSingle && stopResumeOrders?.provider !== 'all' ? (
                <ResumePauseProviderIndex
                  providers={items}
                  updateStopResumeOrders={updateStopResumeOrders}
                  stopResumeOrders={stopResumeOrders}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </MainBlock>
      </Box>
    </Box>
  );
}

export default Main;