import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import AddLanguageDialog from './AddLanguageDialog';
import { addLanguage, deleteLanguage } from 'redux/actions/business';
import Preloader from 'components/Preloader';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import { SET_CURRENT_BUSINESSES } from 'redux/actions/types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { prioritizeEnglish } from 'components/LocalizationPopover/hooks/useLocalizationDialog';
import { SuperAdminButton } from 'redesign/Shared/styles';

const Advanced = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [languages, setLanguages] = useState([]);
  const { currentBusiness } = useSelector(({ business }) => business);
  const [generateTranslation, setGenerateTranslation] = useState(false);

  const sortByAlphabeticall = (list) => {
    const newList = cloneDeep(list);
    const data = newList.sort((a, b) => (a.name > b.name ? 1 : -1));
    return data;
  };

  useEffect(() => {
    if (currentBusiness.languages) {
      const sorted = sortByAlphabeticall(currentBusiness.languages);
      setLanguages(prioritizeEnglish(sorted));
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const sorted = sortByAlphabeticall(languages);
    dispatch({
      type: SET_CURRENT_BUSINESSES,
      payload: { ...currentBusiness, languages: prioritizeEnglish(sorted) },
    });
  }, [languages]);
  const handleClose = () => {
    setOpenDialog(false);
    setSelectedValue({});
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSubmitDialog = () => {
    setLoading(true);
    dispatch(addLanguage({ languageId: selectedValue._id, generateTranslation }))
      .then((res) => {
        setLoading(false);
        const sorted = sortByAlphabeticall([...languages, selectedValue]);
        setLanguages(prioritizeEnglish(sorted));
        // dispatch(getCurrentBusinessInfo(currentBusiness._id));
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteLanguage = async (e, _id) => {
    e.stopPropagation();
    const confirmed = await confirm({
      title: 'confirm_modal.language',
      message: t('settings_advanced.are_you_delete_language'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      setLoading(true);
      dispatch(deleteLanguage(_id))
        .then(() => {
          setLanguages((languages) => languages.filter((item) => item._id !== _id));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      return true;
    }
  };

  const columns = useMemo(() => {
    return [
      // {
      //   field: 'flag',
      //   headerName: 'Flag',
      //   flex: 1,
      //   sortable: false,
      //   filterable: false,
      //   editable: false,
      //   renderCell: ({ row, value }) => {
      //     return (
      //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
      //         {row?.image?.url && (
      //           <Box sx={{ width: '32px', ml: 2 }}>
      //             <img style={{ width: '100%' }} src={row.image.url} alt={row.name} />
      //           </Box>
      //         )}
      //       </Box>
      //     );
      //   },
      // },
      {
        field: 'name',
        headerName: t('Name'),
        flex: 1,
        sortable: false,
        filterable: false,
        editable: false,
        renderCell: ({ row, value }) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                {row?.image?.url && (
                  <Box sx={{ width: '32px', ml: 2 }}>
                    <img style={{ width: '100%' }} src={row.image.url} alt={row.name} />
                  </Box>
                )}
              </Box>
              <Typography>{value} </Typography>
            </Box>
          );
        },
      },
      {
        field: 'code',
        headerName: t('settings_advanced.locale'),
        flex: 1,
        sortable: false,
        editable: false,
        filterable: false,
      },
      //   {
      //     field: 'active',
      //     headerName: 'Active',
      //     sortable: false,
      //     filterable: false,
      //     flex: 1,
      //     editable: false,
      //     renderCell: ({ value }) => {
      //       return (
      //         <>
      //           <Typography sx={{ mr: 1 }}>Autotranslate</Typography>
      //           <img src={value ? CheckActive : CheckInactive} alt="ONLINE_ICON" />
      //         </>
      //       );
      //     },
      //   },
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        hide: false,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              disabled={languages.length <= 1}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={(e) => handleDeleteLanguage(e, params.id)}
            />,
          ];
        },
      },
    ];
  }, [languages, t]);

  return (
    <ErrorBoundaryWrapper>
      {openDialog && (
        <AddLanguageDialog
          savedLanguages={languages}
          loading={loading}
          handleSubmitDialog={handleSubmitDialog}
          open={openDialog}
          handleClose={handleClose}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          languages={languages}
          setGenerateTranslation={setGenerateTranslation}
        />
      )}
      {loading && <Preloader overlay />}
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
            <Typography variant="h5" sx={{ my: 2 }}>
              <LabelTooltip
                id="businessLanguages"
                value={t('settings_advanced.business_languages')}
                tooltip="Business languages"
              />
            </Typography>
            <SuperAdminButton variant="contained" type="button" onClick={handleOpenDialog}>
              <span className="icon icon-plus"></span>
              <span className="">{t('settings_advanced.add_new_language')}</span>
            </SuperAdminButton>
          </Box>
          <Paper>
            <Box
              sx={{
                my: 2,
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: 'white',
                },
                '& .MuiDataGrid-row	': {
                  '&:nth-of-type(odd)': {
                    backgroundColor: (theme) => theme.palette.action.hover,
                  },
                },
                '& .MuiDataGrid-sortIcon': {
                  color: 'white',
                },
                '& .MuiDataGrid-filterIcon': {
                  color: 'white',
                },
                '& .MuiDataGrid-menuIcon	': {
                  '& .MuiButtonBase-root': { color: 'white' },
                },
              }}
            >
              <DataGrid
                title="hello"
                getRowId={(row) => row._id}
                autoHeight={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                isEditable={false}
                rows={languages}
                columns={columns}
                components={{
                  NoRowsOverlay: () => (
                    <Box sx={{ mt: '100px' }}>
                      <EmptyMenu text={t('settings_advanced.there_languages_yet')} />
                    </Box>
                  ),
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </ErrorBoundaryWrapper>
  );
};

export default Advanced;
