import { Grid, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';

const CreateOrEditForm = ({
  visiblePinCode,
  showPinCode,
  jobs = [],
  fields,
  handleAddNewJob,
  handleRemove,
  handleChange,
  handleChangePay,
  currentJobsIds,
}) => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_LEFT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="firstname"
              label={<LabelRequired> {t('First Name')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_RIGHT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="lastname"
              label={<LabelRequired> {t('Last Name')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_LEFT}>
            <CustomMobilePhone
              sx={{ width: '100%', height: '54px' }}
              name="phone"
              label={t('Phone')}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_RIGHT}>
            <CustomTextInput
              sx={{ width: '100%', height: '54px' }}
              name="email"
              label={t('Email Address')}
            />
          </Box>
        </Grid>
        <Grid item md={12} xs={12} lg={12} xl={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_LEFT}>
            <CustomTextInput
              sx={{ width: '100%', height: '84px', '.MuiFormHelperText-root': { width: '100%', ml: '1px' } }}
              name="pinCode"
              helperText={
                'This is the 4 digit numeric code that your employee will enter to log into your system'
              }
              InputProps={{
                type: showPinCode ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={visiblePinCode} edge="end">
                      {showPinCode ? (
                        <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                      ) : (
                        <Visibility sx={{ fontSize: '1.2rem' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={<LabelRequired> {t('Pin Code')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={12} xs={12} lg={12} xl={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        {fields.map((field, index) => {
          return (
            <>
              <Grid item md={6} xs={6} lg={6} xl={6}>
                <Box sx={PADDING_MODAL_ACTION_LEFT}>
                  <CustomTextInput
                    select={true}
                    sx={{ width: '100%', height: '54px' }}
                    name={`jobs[${index}]._id`}
                    label={<LabelRequired> {t('Job Title')}</LabelRequired>}
                    onChange={(event) => handleChange(event, index)}
                  >
                    {jobs.map((item) => {
                      return (
                        <MenuItem
                          sx={{
                            display: currentJobsIds.includes(item._id) ? 'none' : 'block',
                          }}
                          key={item._id}
                          value={item._id}
                        >
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </CustomTextInput>
                </Box>
              </Grid>
              <Grid item md={6} xs={6} lg={6} xl={6}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    width: '100%',
                    alignItems: 'flex-start',
                    ...PADDING_MODAL_ACTION_RIGHT,
                  }}
                >
                  <CustomTextInput
                    sx={{ width: '100%', height: '54px' }}
                    name={`jobs[${index}].pay`}
                    label={t('Pay')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon style={{ color: '929398' }} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => handleChangePay(event, index)}
                  />
                  {index === 0 ? (
                    <IconButton
                      disabled={jobs?.length === fields?.length}
                      aria-label="delete"
                      onClick={handleAddNewJob}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="delete" onClick={() => handleRemove(index)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </>
          );
        })}
        <Grid item md={12} xs={12} lg={12} xl={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid item md={12} xs={12} lg={12} xl={12}>
          <Box sx={{
            ...PADDING_MODAL_ACTION_LEFT,
            ...PADDING_MODAL_ACTION_RIGHT
           }}
          >
            <CustomTextInput sx={{ width: '100%', height: '64px' }} name="externalId" label={t('External Id')} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateOrEditForm;
