import { useEffect } from 'react';
import usePermission from 'hooks/permission';
import { isBrand } from 'hooks/useBrandType';
import { useHistory } from 'react-router-dom';
import * as all from 'constants/permissions';
import usePermissionPlan from 'hooks/permissionPlan';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';

const useRedirectBasedOnPermissions = (currentBusiness) => {
  const history = useHistory();
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();

  const { isTypeBrand } = isBrand();
  const { isTypeSingle } = isPermissionWithCompanyType();

  useEffect(() => {
    if (!currentBusiness || !checkPermissionPlan(all['EXTERNAL_BUNDLE'])) {
      return;
    }

    let targetRoute = '/orders';

    if (isTypeBrand) {
      targetRoute = '/menu';
    } else if (isTypeSingle) {
      targetRoute = checkPermission(all['HOME_REPORT_FILTER']) ? '/home' : '/orders';
    }

    history.push(targetRoute);
  }, [
    currentBusiness,
    checkPermissionPlan,
    checkPermission,
    all,
    isTypeSingle,
    isTypeBrand,
  ]);
};

export default useRedirectBasedOnPermissions;
