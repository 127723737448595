import moment from 'moment';
import dayjs from 'dayjs';
import { DEFAULT_TIMEZONE } from '../components/Chargeback/constants';
const now = new Date();

export const ORDER_GROUP = {
  group: 'group',
  suborder: 'suborder',
  individual: 'individual',
};

export const WIX_FREE_PLAN_VALUE = "0";

export const API_BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://stage-api01.orders.co/' : 'https://api01.orders.co/';
export const PHONE_REGEXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const GOOGLE_API_KEY = 'AIzaSyDYfjHl41cQl4BQZZ35YKPh-sHhL0Vv2ZM';

export const ORDER_STATUS = {
  unconfirmed: {
    name: 'unconfirmed_',
    value: 'unconfirmed',
    color: 'red',
    icon: 'icon-bell',
  },
  confirmed: { name: 'confirmed_', value: 'confirmed', color: 'green' },
  in_progress: { name: 'processing_', value: 'in_progress', color: 'orange' },
  ready_for_pickup: {
    name: 'ready_for_pickup_',
    value: 'ready_for_pickup',
    color: '#00B894',
  },
  picked_up: { name: 'picked_up_', value: 'picked_up', color: '#6C5CE7' },
  delivered: { name: 'delivered_', value: 'delivered', color: '#6C5CE7' },
  rejected: { name: 'rejected_', value: 'rejected', color: 'red' },
  canceled: { name: 'canceled_', value: 'canceled', color: 'red' },
  scheduled: { name: 'scheduled_', value: 'scheduled', color: '#1373E6' },
  completed: { name: 'completed_', value: 'completed', color: '#6C5CE7' },
  rejected_by_orders_co: { name: 'Expired', value: 'rejected_by_orders_co' },
  unknown: { name: 'unknown_', value: 'unknown' },
  closed: { name: 'closed_', value: 'closed' },
  expired: { name: 'expired_', value: 'expired' },
};

export const ORDER_STATUS_SUPER_ADMIN = {
  unconfirmed: {
    name: 'unconfirmed_',
    value: 'unconfirmed',
    color: 'red',
    icon: 'icon-bell',
  },
  in_progress: { name: 'processing_', value: 'in_progress', color: 'orange' },
  rejected: { name: 'rejected_', value: 'rejected', color: 'red' },
  canceled: { name: 'canceled_', value: 'canceled', color: 'red' },
  completed: { name: 'completed_', value: 'completed', color: '#6C5CE7' },
  rejected_by_orders_co: { name: 'Expired', value: 'rejected_by_orders_co' },
  unknown: { name: 'unknown_', value: 'unknown' },
  closed: { name: 'closed_', value: 'closed' },
};
export const INITIAL_STATUS = 'all';
export const ORDERS_FILTER_STATUS = {
  all: { name: 'all_', value: 'all' },
  unconfirmed: { name: 'unconfirmed_', value: 'unconfirmed' },
  in_house: { name: 'processing_', value: 'in_house' },
  ready_for_pickup: { name: 'ready_', value: 'ready_for_pickup' },
  out_the_door: { name: 'picked_up_', value: 'out_the_door' },
  rejected: { name: 'rejected_', value: 'rejected' },
  canceled: { name: 'canceled_', value: 'canceled' },
  scheduled: { name: 'scheduled_', value: 'scheduled' },
  rejected_by_orders_co: { name: 'expired_', value: 'rejected_by_orders_co' },
  unknown: { name: 'unknown_', value: 'unknown' },
  completed: { name: 'completed_', value: 'completed' },
  closed: { name: 'closed_', value: 'closed' },
};

export const ORDERS_FILTER_STATUS_HISTORY = {
  // unconfirmed: { name: 'Unconfirmed', value: 'unconfirmed', color: 'red', icon: 'icon-bell' },
  // in_progress: { name: 'Processing', value: 'in_progress', color: 'orange' },
  completed: { name: 'completed_', value: 'completed', color: '#6C5CE7' },
  rejected: { name: 'rejected_', value: 'rejected', color: 'red' },
  rejected_by_orders_co: { name: 'expired_', value: 'rejected_by_orders_co' },
  unknown: { name: 'unknown_', value: 'unknown' },
  closed: { name: 'closed_', value: 'closed' },
  canceled: { name: 'canceled_', value: 'canceled', color: 'red' },
  // expired: { name: 'Expired', value: 'expired' },
};
export const INITIAL_PERIOD = 'last24h';

export const getPeriods = () => ({
  last24h: {
    label: 'last_24_hours',
    start: Date.now() - 86400000,
    end: +new Date(moment(now).endOf('day')),
  },
  last7d: {
    label: 'last_7_days',
    start: Date.now() - 86400000 * 7,
    end: +new Date(moment(now).endOf('day')),
  },
  lastM: {
    label: 'last_30_days',
    start: Date.now() - 86400000 * 30,
    end: +new Date(moment(now).endOf('day')),
  },
});

export const getReportsFilterPeriodsHome = (timeZoneId = null) => ({
  today: {
    label: 'today',
    start: timeZoneId ? dayjs().tz(timeZoneId).startOf('day').valueOf() : dayjs().startOf('day').valueOf(),
    end: timeZoneId ? dayjs().tz(timeZoneId).endOf('day').valueOf() : dayjs().endOf('day').valueOf(),
  },
  yesterday: {
    label: 'yesterday',
    start: timeZoneId
      ? dayjs().tz(timeZoneId).subtract(1, 'day').startOf('day').valueOf()
      : dayjs().subtract(1, 'day').startOf('day').valueOf(),
    end: timeZoneId
      ? dayjs().tz(timeZoneId).subtract(1, 'day').endOf('day').valueOf()
      : dayjs().subtract(1, 'day').endOf('day').valueOf(),
  },
  week: {
    label: 'Last 7 days',
    start_date: moment
      .tz(timeZoneId || DEFAULT_TIMEZONE)
      .subtract(6, 'days')
      .format('YYYY-MM-DD'),
    end_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
  },
  month: {
    label: 'Last 30 days',
    start_date: moment
      .tz(timeZoneId || DEFAULT_TIMEZONE)
      .subtract(29, 'days')
      .format('YYYY-MM-DD'),
    end_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
  },
});

export const getReportsFilterPeriods = (timeZoneId = null) => ({
  day: {
    label: 'last_24_hours',
    start: timeZoneId ? dayjs.tz().subtract(24, 'h').valueOf() : +new Date(moment(now).subtract('hours', 24)),
    end: timeZoneId
      ? dayjs(+new Date(moment(now)))
          .tz(timeZoneId)
          .valueOf()
      : +new Date(moment(now)),
  },
  week: {
    label: 'last_7_days',
    start: timeZoneId ? dayjs.tz().subtract(1, 'w').startOf('day').valueOf() : new Date() - 86400000 * 7,
    end: timeZoneId
      ? dayjs(+new Date(moment(now)))
          .tz(timeZoneId)
          .endOf('day')
          .valueOf()
      : +new Date(moment(now).endOf('day')),
  },
  month: {
    label: 'last_30_days',
    start: timeZoneId
      ? dayjs.tz().subtract(1, 'M').startOf('day').valueOf()
      : +new Date(moment(now).subtract('days', 30)),
    end: timeZoneId
      ? dayjs(+new Date(moment(now)))
          .tz(timeZoneId)
          .endOf('day')
          .valueOf()
      : +new Date(moment(now).endOf('day')),
  },
});

export const PaymentPeriodicityEnum = {
  monthly: 'monthly',
  yearly: 'yearly',
};
