import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import { CHARGE_BACK_STATUS, checkChargeBackState, SET_CHARGE_SELECTED } from 'components/Chargeback/constants';
import { getChargeBackList } from 'redux/actions/chargeBack';
import TableChargeBack from 'components/Chargeback/Table/TableChargeBack';
import useProviderConfig from 'hooks/useProviderConfig';
import { STYLES } from 'components/Chargeback/Details/styles';
import { formatNumber } from 'helpers/formPrice';
import holdImage from 'assets/icons/chargeBack/hold_circle.png';
import scheduleImage from 'assets/icons/chargeBack/schedule_circle.png';
import approvedImage from 'assets/icons/chargeBack/approve_circle.png';
import cancelImage from 'assets/icons/chargeBack/cancel_circle.png';
import blackAlertImage from 'assets/icons/chargeBack/black_alert_circle.png';
import solvedByProviderImage from 'assets/icons/chargeBack/solved_by_provider_circle.png';
import refundedImage from 'assets/icons/chargeBack/refunded_circle.png';
import createImage from 'assets/icons/chargeBack/create_circle.png';
import UpdatePlan from 'components/Chargeback/UpdatePlan';
import useRedirectBasedOnPermissions from 'components/Chargeback/hooks/useRedirectBasedOnPermissions';

const ToolTipWrap = ({ title, children }) => {
  return (
    <Box>
      <Tooltip title={title} placement="top">
        {children}
      </Tooltip>
    </Box>
  );
};

const ChargeBack = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const stateChargeBack = checkChargeBackState(history.location.pathname);
  const { providers } = useProviderConfig();
  const {
    chargeBack,
    business: { currentBusiness },
  } = useSelector((store) => store);
  useRedirectBasedOnPermissions(currentBusiness);
  const checkPlan = currentBusiness?.settings?.disputeSettings?.enabled;

  useEffect(() => {
    dispatch({ type: SET_CHARGE_SELECTED, payload: null });
    dispatch(getChargeBackList(stateChargeBack));
  }, []);

  const infoObj = (row) => {
    switch (row?.status?.name?.toLowerCase()) {
      case CHARGE_BACK_STATUS.CREATED:
        return {
          icon: createImage,
          statusInfoText: STYLES.statusInfoTextCreatedRow,
          background: STYLES.backgroundCreated,
        };
      case CHARGE_BACK_STATUS.HOLD:
        return {
          icon: holdImage,
          statusInfoText: STYLES.statusInfoTextHoldRow,
          background: STYLES.backgroundHold,
        };
      case CHARGE_BACK_STATUS.DISPUTED:
        return {
          icon: scheduleImage,
          statusInfoText: STYLES.statusInfoTextDisputedRow,
          background: STYLES.backgroundDisputed,
        };
      case CHARGE_BACK_STATUS.DISPUTE_APPROVED:
        return {
          icon: approvedImage,
          statusInfoText: STYLES.statusInfoTextDisputeApprovedRow,
          background: STYLES.backgroundDisputeApproved,
        };
      case CHARGE_BACK_STATUS.REJECTED:
        return {
          icon: cancelImage,
          statusInfoText: STYLES.statusInfoTextRejectedRow,
          background: STYLES.backgroundRejected,
        };
      case CHARGE_BACK_STATUS.EXPIRED:
      case CHARGE_BACK_STATUS.UNKNOWN:
      case CHARGE_BACK_STATUS.DISPUTE_NOT_ALLOWED:
        return {
          icon: blackAlertImage,
          statusInfoText: STYLES.statusInfoTextExpiredRow,
          background: STYLES.backgroundExpired,
        };
      case CHARGE_BACK_STATUS.SOLVED_BY_PROVIDER:
        return {
          icon: solvedByProviderImage,
          statusInfoText: STYLES.statusInfoTextExpiredRow,
          background: STYLES.backgroundRefunded,
        };
      case CHARGE_BACK_STATUS.ACCEPTED:
        return {
          icon: cancelImage,
          statusInfoText: STYLES.statusInfoTextRejectedRow,
          background: STYLES.backgroundRejected,
        };
      case CHARGE_BACK_STATUS.REFUNDED:
        return {
          icon: refundedImage,
          statusInfoText: STYLES.statusInfoTextRefundedRow,
          background: STYLES.backgroundRefunded,
        };
      default:
        return {
          icon: cancelImage,
          statusInfoText: STYLES.statusInfoTextRejectedRow,
          background: STYLES.backgroundRejected,
        };
    }
  };

  const styleTypography = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'block',
    fontWeight: 500,
  };

  const rows = chargeBack.states[stateChargeBack.toLowerCase()]?.map((item) => {
    return {
      id: item._id,
      provider: item.provider,
      client: item.customerName,
      order: item.orderNumber,
      status: item.status,
      amount: item.nativeOrderTotal ?? item.orderTotal,
      chargeBack: item?.chargeTotal,
      refund: item?.refundTotal,
    };
  });

  const columns = [
    {
      field: 'provider',
      headerName: t('chargeBack.headerTable.provider'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        console.log('value: ', value);
        return (
          <img src={providers[value?.toLowerCase()]?.logoUrl} width="40" height="40" alt="order" className="rounded" />
        );
      },
    },
    {
      field: 'client',
      headerName: t('chargeBack.headerTable.client'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return (
          <ToolTipWrap title={value}>
            <Typography sx={styleTypography}>{value}</Typography>
          </ToolTipWrap>
        );
      },
    },
    {
      field: 'order',
      headerName: t('chargeBack.headerTable.order'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return (
          <ToolTipWrap title={value}>
            <Typography sx={styleTypography}>#{value}</Typography>
          </ToolTipWrap>
        );
      },
    },
    {
      field: 'status',
      headerName: t('chargeBack.headerTable.status'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      renderCell: ({ value, row }) => {
        return (
          <ToolTipWrap title={value?.status?.displayName}>
            <Box
              sx={{
                ...infoObj(row).background,
                display: 'flex',
                borderRadius: '4px',
                padding: '2px 6px 2px 4px',
                alignItems: 'center',
              }}
            >
              <Box component="img" sx={STYLES.statusIcon} src={infoObj(row).icon} alt={value?.name} />
              <Typography
                sx={{
                  ...styleTypography,
                  ...infoObj(row).statusInfoText,
                }}
              >
                {value?.status?.displayName}
              </Typography>
            </Box>
          </ToolTipWrap>
        );
      },
    },
    {
      field: 'amount',
      headerName: t('chargeBack.headerTable.amount'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      align: 'right',
      renderCell: ({ value }) => {
        return value ? (
          <ToolTipWrap title={value}>
            <Typography sx={styleTypography}>${formatNumber(value)}</Typography>
          </ToolTipWrap>
        ) : (
          <Typography sx={styleTypography}>-</Typography>
        );
      },
    },
    {
      field: 'chargeBack',
      headerName: t('chargeBack.headerTable.chargeBack'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      align: 'right',
      renderCell: ({ value }) => {
        return (
          <ToolTipWrap title={value}>
            <Typography sx={styleTypography} color={value ? '#E21508' : '#0F1214'}>
              {value ? `-$${formatNumber(value)}` : `$${formatNumber(value)}`}
            </Typography>
          </ToolTipWrap>
        );
      },
    },
  ];

  if (stateChargeBack.toLowerCase() === 'completed') {
    columns.push({
      field: 'refund',
      headerName: t('chargeBack.headerTable.refund'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      align: 'right',
      renderCell: ({ value }) => {
        return value ? (
          <ToolTipWrap title={value}>
            <Typography sx={styleTypography} color={value ? '#00984F' : '#0F1214'}>
              ${formatNumber(value)}
            </Typography>
          </ToolTipWrap>
        ) : (
          <Typography sx={styleTypography}>-</Typography>
        );
      },
    });
  }

  return (
    <Box>
      {!checkPlan ? (
        <UpdatePlan />
      ) : (
        <TableChargeBack columns={columns || []} rows={rows || []} name={stateChargeBack} />
      )}
    </Box>
  );
};

export default ChargeBack;
