import Preloader from 'components/Preloader';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispute } from 'pages/Settings/Dispute/useDispute';
import { SuperAdminLoadingButton } from 'redesign/Shared/styles';
import { BusinessSettingsAllTitle } from 'pages/Settings/BusinessSettings/styles';
import { Box, Divider, Grid, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';

const DisputeSettings = () => {
  const { t } = useTranslation();
  const {
    businessDisputeSettings,
    isBrandLoading,
    handleDisputeSettingsSubmit,
    checkedAutoDispute,
    checkedDispute,
    handleChangeDispute,
    handleChangeAutoDispute,
    checkEmailNotification,
    handleChangeEmailNotification,
    loading,
  } = useDispute();
  return (
    <>
      {isBrandLoading && <Preloader />}
      {!isBrandLoading && (
        <>
          <Divider />
          <Box sx={{ mt: 3 }}>
            <FormProvider {...businessDisputeSettings}>
              <form onSubmit={businessDisputeSettings.handleSubmit(handleDisputeSettingsSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <BusinessSettingsAllTitle>{t('settings.dispute_settings')}</BusinessSettingsAllTitle>
                  </Grid>
                  <Box>
                    <Box sx={{ maxWidth: '176px' }}>
                      <Grid item xs={12} sx={{ mt: 3 }}>
                        <FormGroup checked={checkedDispute} onChange={handleChangeDispute} name={`enabled`}>
                          <FormControlLabel
                            control={<Switch checked={checkedDispute} />}
                            label={
                              <Typography sx={{ color: '#3C4350', fontWeight: 500, fontSize: '16px', width: '100%' }}>
                                {t('settings.enable_dispute_settings')}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Box>
                    {checkedDispute ? (
                      <Box sx={{ maxWidth: '160px', mt: 1 }}>
                        <Grid item xs={12}>
                          <FormGroup
                            checked={checkedAutoDispute}
                            onChange={handleChangeAutoDispute}
                            name={`autoDispute`}
                          >
                            <FormControlLabel
                              control={<Switch checked={checkedAutoDispute} />}
                              label={
                                <Typography sx={{ color: '#3C4350', fontWeight: 500, fontSize: '16px' }}>
                                  {t('settings.auto_dispute_settings')}
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </Grid>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {checkedDispute ? (
                      <Box sx={{ maxWidth: '260px', mt: 1 }}>
                        <Grid item xs={12}>
                          <FormGroup
                            checked={checkEmailNotification}
                            onChange={handleChangeEmailNotification}
                            name={`emailNotification`}
                          >
                            <FormControlLabel
                              control={<Switch checked={checkEmailNotification} />}
                              label={
                                <Typography sx={{ color: '#3C4350', fontWeight: 500, fontSize: '16px' }}>
                                  {t('settings.enable_email_settings')}
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </Grid>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <SuperAdminLoadingButton
                    disabled={!businessDisputeSettings.formState.isDirty || loading}
                    variant="contained"
                    type="submit"
                    sx={{ width: '71px', mt: 2 }}
                    loading={loading}
                  >
                    {t('orders.save')}
                  </SuperAdminLoadingButton>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
};
export default DisputeSettings;
