import React, { useContext } from 'react';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProviderStatusItem from './ProviderStatusItem';
import useProviderConfig from 'hooks/useProviderConfig';
import { StyledButton, StyledButtonBox } from './styles';
import { PauseResumeContext } from 'context/pauseResumeContext';
import { useProviderTimer } from 'components/PauseResume/hooks/useProviderTimer';

const ProviderStatusPopover = ({ handleOpenModal = null, id }) => {
  const { providers } = useProviderConfig();
  const { t } = useTranslation();
  const {
    isOpen,
    anchorEl,
    handlePopoverClose,
    filteredProviders,
    iconStyle,
    isShowButton
  } = useContext(PauseResumeContext);
  const updatedProviders = useProviderTimer(filteredProviders);

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <>
        {updatedProviders.map((item) => {
          const { logoUrl, label } = providers[item?.provider];
          return (
            <React.Fragment key={label}>
              <ProviderStatusItem
                logoUrl={logoUrl}
                label={label}
                iconStyle={iconStyle(item?.provider_status)}
                showDivider={true}
                item={item}
                showTime={true}
                currentDuration={item?.time_remaining}
              />
            </React.Fragment>
          );
        })}
        {!isShowButton ? (
          <></>
        ) : (
          <StyledButtonBox onClick={handleOpenModal ? handleOpenModal : null}>
            <StyledButton
              color="primary"
              variant="outlined"
              type={'button'}
            >
              {t('Change')}
            </StyledButton>
          </StyledButtonBox>
        )}
      </>
    </Popover>
  );
};
export default ProviderStatusPopover;
