import { memo, useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import instance from 'services/api';
import { useSelector } from 'react-redux';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SuperAdminLoadingButton } from 'redesign/Shared/styles';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { vibeCheckSettingsSchema } from 'pages/Settings/VibeCheckSettings/vibeCheckSettingsSchema';
import { BusinessSettingsSubTitle, BusinessSettingsTitle } from 'pages/Settings/BusinessSettings/styles';;

const VibeCheckSettings = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const { currentBusiness, isBrandLoading } = useSelector(({ business }) => business);
  const [currentBusinessState, setCurrentBusinessState] = useState(currentBusiness || {});
  const defaultVibeCheckSettings = {
    vibeCheckEnabled: false,
  };

  const vibeCheckSettings = useForm({
    defaultValues: currentBusinessState?.vibeCheckEnabled || defaultVibeCheckSettings,
    mode: 'all',
    resolver: yupResolver(vibeCheckSettingsSchema),
  });

  useEffect(() => {
    const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
    if (businessId) {
      return instance
        .get(`business/login/${businessId}`)
        .then((res) => {
          setCurrentBusinessState(res?.data?.data);
          if (!res?.data?.data?.hasOwnProperty('vibeCheckEnabled')) {
            setChecked(false);
          } else {
            setChecked(res?.data?.data?.vibeCheckEnabled);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChangeVibeCheckSettings = (event) => {
    vibeCheckSettings.reset({ vibeCheckEnabled: event.target.checked }, { keepDefaultValues: true, isDirty: true });
    setChecked(event.target.checked);
    setDisabledButton(false);
  };

  useEffect(() => {
    if (currentBusiness) {
      setCurrentBusinessState(currentBusiness);
    }
  }, [currentBusiness]);

  const handleVibeCheckSettingsSubmit = (values) => {
    setLoading(true);
    return instance
      .patch(`/business/vibe-check`, values)
      .then((res) => {
        setChecked(res?.data?.data?.vibeCheckEnabled);
        vibeCheckSettings.reset({ vibeCheckEnabled: res?.data?.data?.vibeCheckEnabled }, { keepDirty: false });
        setDisabledButton(true);
        setLoading(false);
        toast.success('Setting is successfully saved');
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {isBrandLoading && <Preloader />}
      {!isBrandLoading && (
        <FormProvider {...vibeCheckSettings}>
          <ConfirmPromptModal
            handleConfirm={vibeCheckSettings.handleSubmit(handleVibeCheckSettingsSubmit)}
            submitData={vibeCheckSettings.getValues()}
            hasUnsavedChanges={vibeCheckSettings.formState.isDirty}
            formState={vibeCheckSettings}
          />
          <form onSubmit={vibeCheckSettings.handleSubmit(handleVibeCheckSettingsSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <BusinessSettingsTitle>{t('settings.review_settings')}</BusinessSettingsTitle>
                <BusinessSettingsSubTitle>{t('settings.vibeCheck_settings')}</BusinessSettingsSubTitle>
              </Grid>
              <Grid item xs={12}>
                <FormGroup checked={checked} onChange={handleChangeVibeCheckSettings} name={`vibeCheckEnabled`}>
                  <FormControlLabel control={<Switch checked={checked} />} label={t('settings.enable_vibeCheck')} />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <SuperAdminLoadingButton
                  id="vibeCheckSettingsLoading"
                  disabled={disabledButton || loading}
                  variant="contained"
                  type="submit"
                  sx={{ width: '200px', mt: 2, alignItems: 'right', p: 1 }}
                  loading={loading}
                >
                  {t('settings.save')}
                </SuperAdminLoadingButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </>
  );
};
export default memo(VibeCheckSettings);
