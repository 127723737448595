import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { updatePaymentSystem } from '../../../../redux/actions/business';
import { useDispatch } from 'react-redux';
const schemaPayment = yup
  .object()
  .shape({
    paymentSystem: yup
      .string()
      .oneOf(['stripe', 'forward'], 'Invalid payment system selected')
      .required('Payment system is required'),

    accountId: yup
      .string()
      .required('Account ID is required'),
  })
  .required();

const defaultValues = {
  paymentSystem: '',
  accountId: '',
};

export const paymentSystems = [
  { value: 'stripe', label: 'Stripe' },
  { value: 'forward', label: 'Forward' },
]

const useChangePaymentSystem = (open, handleClose, websiteFormMethods) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const paymentSystem = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaPayment),
  });

  useEffect(() => {
    if (!open) {
      paymentSystem.reset(defaultValues);
    }
  }, [open]);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(updatePaymentSystem(values)).then(() => {
      websiteFormMethods.setValue('paymentSystem', values.paymentSystem);
      handleClose();
      paymentSystem.reset(defaultValues);
      window.location.reload();
    }).finally(() =>  setLoading(false));
  }

  return {
    paymentSystem,
    handleSubmit,
    loading,
  }
}
export default useChangePaymentSystem;