import useWixFreePlan from 'hooks/useWixFreePlan';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Container, Typography, Button, Box } from "@mui/material";
import UpdatedPlanIcon from 'assets/icons/chargeBack/updated_plan_icon.svg';

const UpgradeNotice = () => {
  const { upgradeUrl } = useWixFreePlan();

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Box component="img" src={UpdatedPlanIcon} alt="Updated Plan Icon" sx={{ mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        Your current subscription for Orders.co Connect does not allow access to this feature.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please upgrade your plan to enable access.
      </Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          href={upgradeUrl}
          target="_blank"
          rel="noopener noreferrer"
          endIcon={<OpenInNewIcon />}
        >
          Upgrade Now
        </Button>
      </Box>
    </Container>
  );
};

export default UpgradeNotice;
