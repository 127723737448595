import { useRef, useState } from 'react';
import { pinCodeCheck } from 'redux/actions/providers';
import { useDispatch, useSelector } from 'react-redux';

export const useProviderStatusHover = () => {
  const dispatch = useDispatch();
  const pinRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentBusiness } = useSelector(({ business }) => business);
  const [showPinModal, setShowPinModal] = useState(false);
  const [anchorElementTarget, setAnchorElementTarget] = useState(null);

  const handleClosePinModal = () => {
    setShowPinModal(false);
    setAnchorEl(null);
    setAnchorElementTarget(null)
  }

  const onCompleteCode = (value) => {
    dispatch(pinCodeCheck(value))
      .then(() => {
        setTimeout(() => {
          setShowPinModal(false);
          setAnchorEl(anchorElementTarget);
        }, 500);
      })
      .catch(() => {
        pinRef?.current?.clear();
      });
  };

  const handlePopoverOpen = (event) => {
    const currentTarget = event.currentTarget;
    if (currentBusiness?.settings?.pinCode?.enable) {
      setShowPinModal(true);
      setAnchorElementTarget(currentTarget);
      return;
    }
    setShowPinModal(false);
    setAnchorEl(currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorElementTarget(null)
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  return {
    anchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isOpen,
    id,
    onCompleteCode,
    showPinModal,
    pinRef,
    handleClosePinModal
  };
};
