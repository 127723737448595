import { createContext, useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useProviderConfig from 'hooks/useProviderConfig';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { useProviderStatusHover } from 'hooks/useProviderStatusHover';

const selectAllObject = {
  provider: 'select_all',
  message: '',
  provider_status: '',
  paused_until: '',
  immutable_until: '',
  label: 'Select all',
};

const businessSelectAllObject = {
  address: '',
  businessId: 'select_all',
  companyName: 'Select all',
  label: '',
  value: 'select_all',
};

export const CHOOSE_STORE = {
  ONLY_ONE_STORE: 'onlyOneStore',
  ALL_STORE: 'allStore',
};

export const initialData = {};
export const PauseResumeContext = createContext(initialData);
export const PauseResumeProvider = ({ children }) => {
  const { providers } = useProviderConfig();
  const { providers: providerConfig } = useProviderConfig();
  const boxRef = useRef(null);

  const {
    anchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isOpen,
    id,
    showPinModal,
    onCompleteCode,
    pinRef,
    handleClosePinModal,
  } = useProviderStatusHover();

  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const [checkedProvider, setCheckedProvider] = useState([]);
  const [checkedBusiness, setCheckedBusiness] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllBusiness, setSelectAllBusiness] = useState(false);
  
  const {
    statuses: { details },
  } = stopResumeOrders;

  function getUniqueStatuses(providers) {
    if (providers) {
      const statusSet = new Set();
      providers.forEach((provider) => {
        if (
          (provider?.provider_status === PAUSE_RESUME_STATUSES.PAUSED && !provider?.paused_until)
          || (!providerConfig[provider?.provider]?.providerStatusSupported) || !provider?.provider_status) {
          provider.provider_status = PAUSE_RESUME_STATUSES.NA;
        }
        statusSet.add(provider.provider_status);
      });
      return Array.from(statusSet);
    }
  }

  function getProviderStatusSupported(providers) {
    if (Array.isArray(providers)) {
      return providers.some(provider => {
        const providerKey = provider?.provider;
        const isSupported = providerConfig[providerKey]?.providerStatusSupported;
        return Boolean(isSupported);
      });
    }
    return true;
  }

  const filteredProviders = details?.filter((status) => providers[status?.provider] && status?.provider !== 'website');
  const isSameStatusProvider = getUniqueStatuses(filteredProviders);
  const isShowButton = getProviderStatusSupported(filteredProviders);

  const iconStyle = useCallback((status) => {
    const styleObject = { fontSize: '16px', mt: '6px' };
    if (status === PAUSE_RESUME_STATUSES.RECEIVING) return { ...styleObject, color: '#11AF22' };
    if (status === PAUSE_RESUME_STATUSES.BUSY)
      return {
        ...styleObject,
        color: '#FF8A00',
      };
    if (status === PAUSE_RESUME_STATUSES.NA)
      return {
        ...styleObject,
        color: 'red',
      };
    return {
      ...styleObject,
      color: 'red',
    };
  }, []);

  return (
    <PauseResumeContext.Provider
      value={{
        filteredProviders,
        iconStyle,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        isOpen,
        selectAllObject,
        checkedProvider,
        selectAll,
        setCheckedProvider,
        setSelectAll,
        setCheckedBusiness,
        checkedBusiness,
        setSelectAllBusiness,
        selectAllBusiness,
        businessSelectAllObject,
        boxRef,
        id,
        isSameStatusProvider,
        showPinModal,
        onCompleteCode,
        pinRef,
        handleClosePinModal,
        isShowButton,
      }}
    >
      {children}
    </PauseResumeContext.Provider>
  );
};
