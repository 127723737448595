import styled from '@emotion/styled';
import { Box, Divider, Typography } from '@mui/material';

export const DropTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  marginBottom: theme.spacing(2),
}));

export const DropOptionContainer = styled(Box)({
  display: 'flex',
  gap: '16px'
});

export const DropOfDivider = styled(Divider)(({ theme }) =>({
  margin: theme.spacing(2,0,3,0),
}));