import { useEffect, useState } from 'react';
import instance from 'services/api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import FormGroup from '@mui/material/FormGroup';
import { directorySchema } from './directorySchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { BusinessSettingsTitle } from '../BusinessSettings/styles';
import { SuperAdminLoadingButton } from 'redesign/Shared/styles';
import PopularItemCoupon from 'pages/Settings/Directory/PopularItemCoupon';
import { Box, Grid, FormControlLabel, Switch, Typography } from '@mui/material';
import PopularItemDirectory from 'pages/Settings/Directory/PopularItemDirectory';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const directorySettingsDefault = {
  directoryEnabled: false,
  isTest: false,
};
const Directory = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [update, setUpdate] = useState(false);
  const [endpointDirectoryChecked, setEndpointDirectoryChecked] = useState(false);
  const [isTestChecked, setIsTestChecked] = useState(true);
  const [loadCurrentBusiness, setLoadCurrentBusiness] = useState(false);
  const [directoryOptions, setDirectoryOptions] = useState({
    directoryId: '',
    directoryUrl: '',
  });
  const directorySettings = useForm({
    defaultValues: directorySettingsDefault,
    mode: 'all',
    resolver: yupResolver(directorySchema),
  });
  const handleChangeDirectorCheckSettings = (event) => {
    directorySettings.setValue('directoryEnabled', event.target.checked, { shouldDirty: true });
    setChecked(event.target.checked);
    setIsDirty(Boolean(event.target.checked));
  };

  const handleChange = (event) => {
    directorySettings.setValue('isTest', event.target.checked, { shouldDirty: true });
    setIsTestChecked(event.target.checked);
  };

  const handleCancel = () => {
    setChecked(endpointDirectoryChecked);
    directorySettings.setValue('directoryEnabled', endpointDirectoryChecked);
    directorySettings.setValue('isTest', true);
    setIsTestChecked(true);
    setIsDirty(false);
  };

  useEffect(() => {
    const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
    if (businessId) {
      setLoadCurrentBusiness(true);
      return instance
        .get(`business/login/${businessId}`)
        .then((res) => {
          setIsDirty(false);
          setDirectoryOptions({
            directoryId: res?.data?.data?.directoryId || '',
            directoryUrl: res?.data?.data?.directoryUrl || '',
          });
          if (res?.data?.data.hasOwnProperty('isTest')) {
            setIsTestChecked(!res?.data?.data?.isTest);
            directorySettings.setValue('isTest', !res?.data?.data?.isTest, { shouldDirty: false });
          } else {
            setIsTestChecked(true);
            directorySettings.setValue('isTest', true, { shouldDirty: false });
          }
          setEndpointDirectoryChecked(res?.data?.data?.directoryEnabled);
          setChecked(res?.data?.data?.directoryEnabled || false);
          directorySettings.setValue('directoryEnabled', res?.data?.data?.directoryEnabled || false, {
            shouldDirty: false,
          });
          setLoadCurrentBusiness(false);
        })
        .catch(() => setLoadCurrentBusiness(false));
    }
  }, []);

  const handleDirectoryCheckSettingsSubmit = (values, isUpdated = false) => {
    setLoading(true);
    values.isTest = !values?.isTest;
    return instance
      .patch(`/business/directory`, values)
      .then((res) => {
        setLoading(false);
        setIsDirty(false);
        setDirectoryOptions({
          directoryId: res?.data?.data?.directoryId || '',
          directoryUrl: res?.data?.data?.directoryUrl || '',
        });
        if (res?.data?.data.hasOwnProperty('isTest')) {
          setIsTestChecked(!res?.data?.data?.isTest);
          directorySettings.setValue('isTest', !res?.data?.data?.isTest, { shouldDirty: false });
        } else {
          setIsTestChecked(true);
          directorySettings.setValue('isTest', true, { shouldDirty: false });
        }
        setChecked(res?.data?.data?.directoryEnabled);
        directorySettings.setValue('directoryEnabled', res?.data?.data?.directoryEnabled, { shouldDirty: false });
        if (isUpdated) {
          toast.success('Setting is successfully updated');
        } else toast.success('Setting is successfully saved');
        setUpdate(false);
      })
      .catch(() => {
        setLoading(false);
        setUpdate(false);
      });
  };
  const handleUpdate = () => {
    setUpdate(true);
    const updateAttachSub = directorySettings.getValues();
    handleDirectoryCheckSettingsSubmit({ update: true, isTest: updateAttachSub.isTest }, true);
  };

  return (
    <ErrorBoundaryWrapper>
      {loadCurrentBusiness && <Preloader />}
      {!loadCurrentBusiness && (
        <FormProvider {...directorySettings}>
          <ConfirmPromptModal
            handleConfirm={directorySettings.handleSubmit(handleDirectoryCheckSettingsSubmit)}
            submitData={directorySettings.getValues()}
            hasUnsavedChanges={isDirty}
            formState={directorySettings}
          />
          <form onSubmit={directorySettings.handleSubmit(handleDirectoryCheckSettingsSubmit)}>
            <Grid container spacing={2} columns={{ xs: 12 }}>
              <Grid item xs={12}>
                <BusinessSettingsTitle>{t('settings_directory.business_directory_settings')}</BusinessSettingsTitle>
              </Grid>
              <Grid item xs={4}>
                <FormGroup
                  sx={{ width: '300px' }}
                  checked={checked}
                  onChange={handleChangeDirectorCheckSettings}
                  name={`vibeCheckEnabled`}
                >
                  <FormControlLabel
                    disabled={Boolean(directoryOptions?.directoryId && directoryOptions?.directoryUrl)}
                    control={<Switch checked={checked} />}
                    label={t('settings_directory.enable_directory_listing')}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup sx={{ width: '210px' }} checked={isTestChecked} onChange={handleChange} name={`isTest`}>
                  <FormControlLabel
                    disabled={
                      !checked || Boolean(directoryOptions?.directoryId && directoryOptions?.directoryUrl)
                    }
                    control={<Switch checked={isTestChecked} />}
                    label={t('settings_directory.attach_subscription')}
                  />
                </FormGroup>
              </Grid>
              {directoryOptions?.directoryId !== '' && directoryOptions?.directoryUrl !== '' ? (
                <Grid item xs={12}>
                  <Typography>
                    {t('settings_directory.manage_listing')}:&nbsp;
                    <a href={directoryOptions?.directoryUrl} target={'_blank'} rel="noreferrer">
                      {directoryOptions?.directoryUrl}
                    </a>{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    {t('settings_directory.businessId')}:&nbsp;{directoryOptions?.directoryId}{' '}
                  </Typography>
                </Grid>
              ) : (
                <> </>
              )}
              <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: '50%', display: 'flex', gap: '20px' }}>
                  <LoadingButton
                    id="directoryCancel"
                    disabled={loading || !isDirty}
                    variant="outlined"
                    color="primary"
                    sx={{ width: '200px', mt: 2, alignItems: 'right', p: 1 }}
                    onClick={handleCancel}
                  >
                    {t('orders.cancel')}
                  </LoadingButton>
                  <SuperAdminLoadingButton
                    id="directorySave"
                    disabled={loading || !isDirty}
                    variant="contained"
                    type="submit"
                    sx={{ width: '200px', mt: 2, alignItems: 'right', p: 1 }}
                    loading={loading && !update}
                  >
                    {t('orders.save')}
                  </SuperAdminLoadingButton>
                  {directoryOptions?.directoryId !== '' && directoryOptions?.directoryUrl !== '' ? (
                    <SuperAdminLoadingButton
                      id="directoryLoading"
                      disabled={loading}
                      variant="contained"
                      type="submit"
                      sx={{ width: '200', mt: 2, alignItems: 'right', p: 1 }}
                      loading={loading && update}
                      onClick={handleUpdate}
                    >
                      {t('settings_directory.update_all_changes')}
                    </SuperAdminLoadingButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
      <PopularItemDirectory />
      <PopularItemCoupon />
    </ErrorBoundaryWrapper>
  );
};
export default Directory;
