import { Card, Button } from '@mui/material';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import Sales from 'components/NewHome/Sales/Sales';
import usePermissionPlan from 'hooks/permissionPlan';
import TopSellingItems from 'components/NewHome/TopSellingItems';
import ChargeBackReport from 'components/NewHome/ChargeBack/ChargeBackReport';
import ProviderReport from 'components/NewHome/ProviderReport/ProviderReport';

const NewHome = () => {
  const checkPermissionPlan = usePermissionPlan();
  const checkPermission = usePermission();

  const handleBoarding = () => {
    const business = JSON.parse(localStorage.getItem('businessInfo'));
    window.open(`${process.env.REACT_APP_ON_BOARDING_URL}?businessId=${business?.id}`, '_blank');
  }

  return (
    <>
      {checkPermission(all['SUPER']) && (
        <Card sx={{ p: 2, mb: 2 }}>
          <Button variant='contained' onClick={handleBoarding}>Onboarding</Button>
        </Card>
      )}
      {(checkPermission(all['SUPER']) ||
        !checkPermissionPlan(all['EXTERNAL_BUNDLE'])) && <ChargeBackReport />}
      <Sales />
      <ProviderReport />
      <TopSellingItems />
    </>
  );
};

export default NewHome;
