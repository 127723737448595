import { memo } from 'react';
import { objEnum } from '../utils';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  DETAILED_ORDER_REPORT_FIELDS,
  numberFields,
  numberFieldsTotals,
  numberFieldsWithoutPrice,
  styles
} from 'pages/Report/hooks/useTableReport';

const ReportTotal = ({ headers, totals, transformTableType }) => {
  const { t } = useTranslation();

  const columns = headers
    .filter(header =>
      numberFieldsTotals.includes(header?.field) &&
      header?.headerName &&
      totals[header.field] != null && totals[header.field] !== ''
    )
    .map((header) => ({
      field: header?.field,
      headerName: header.headerName,
      flex: 1,
      hide: Boolean(
        transformTableType === t(`${objEnum.DETAILED_ORDER_REPORT}`) &&
        DETAILED_ORDER_REPORT_FIELDS.includes(header.headerName)
      ),
      align: numberFieldsTotals.includes(header?.field) ? 'right' : 'left',

      filterable: false,
      renderCell: (params) => {
        const isNumberField = numberFieldsTotals.includes(header.field);
        const isPrice = !numberFieldsWithoutPrice.includes(header.field);
        const fieldTotal = totals[params.field];
        const hasValue = fieldTotal !== null && fieldTotal !== undefined;

        if (isNumberField && isPrice && hasValue) {
          return `$${fieldTotal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        } else if (hasValue) {
          return fieldTotal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return '';
        }
      },
    }));


  const rows = [
    headers?.reduce((acc, header) => {
      acc[header?.field] = totals[header?.field] || '';
      return acc;
    }, { id: 1 }),
  ];

  return (
    <Box sx={{ ...styles }}>
      <Typography
        sx={{
          fontWeight: 600,
          pt: 2,
          pb: 2,
          fontSize: '18px'
        }}
      >{t('Summary')}</Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        hideFooter
        autoHeight
        density="compact"
      />
    </Box>
  );
};

export default memo(ReportTotal);
