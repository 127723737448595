// src/components/Sidebar.jsx

import React, { useCallback, useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ORDERS_HISTORY, RESET_ORDERS_PAGINATION } from 'redux/actions/types';
import { logout } from 'redux/actions/user';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import IconButton from '@mui/material/IconButton';
import { useSocket } from 'providers/Socket';
import { useDispatch, useSelector } from 'react-redux';
import Private from './Private';
import PrivatePlan from './PrivatePlan';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { isBrand } from 'hooks/useBrandType';
import { IS_LOGIN_USER, MODIFIED_LOG_OUT } from 'constants/user';
import { isTableOrderingProvider } from 'utils/checkAccessDenied';
import usePermission from '../hooks/permission';
import * as all from 'constants/permissions';
import SkeletonSidebarList, {
  SkeletonWrap,
} from './Settings/Skeleton/SkeletonSidebarList';
import usePermissionPlan from 'hooks/permissionPlan';
import { useTranslation } from 'react-i18next';
import { confirm } from './MenuNew/ConfirmModal';
import { ListItemButton } from '@mui/material';

// Material UI Icons
import HomeIcon from '@mui/icons-material/Home';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HistoryIcon from '@mui/icons-material/History';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import LanguageIcon from '@mui/icons-material/Language';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const excludePages = ['/website/main', '/website/coupons', '/providers', '/'];
const excludePagesBrand = [
  '/website/main',
  '/website/coupons',
  '/providers',
  '/',
  '/reports',
  '/orders',
  '/orders/history',
];

const Sidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const planId = searchParams.get('plan');
  const corporateBusiness = searchParams.get('corporateBusiness');
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  const { data, isLogin, modifiedLogOut } = useSelector(({ user }) => user);
  const { currentBusiness } = useSelector(({ business }) => business);
  const providers = useSelector(({ providers }) => providers);
  const { newOrders } = useSelector(({ orders }) => orders);
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { isTypeBrand } = isBrand();
  const { eventEmit } = useSocket();
  const [clickLogOut, setClickLogOut] = useState(false);

  const providerCheck =
    window.location.pathname.includes('/providers') &&
    checkPermissionPlan(all['APP_BUNDLE']) &&
    checkPermission(all['PROVIDERS_VIEW']) &&
    isTypeSingle
      ? !providers?.items?.length
      : 0;

  const skeletonLoading = !currentBusiness?.roles || !data || providerCheck;

  const handleLogout = useCallback(async () => {
    dispatch({ type: MODIFIED_LOG_OUT, payload: true });
    setClickLogOut(true);
    history.push({
      pathname: history.location.pathname,
      state: {
        logOut: true,
      },
    });
  }, [dispatch, history]);

  const openConfirmPopup = useCallback(async () => {
    if (clickLogOut && modifiedLogOut !== 'opened' && modifiedLogOut) {
      const confirmed = await confirm({
        title: t('providers_settings.are_you_sure'),
        message: t('providers_settings.do_you_logout'),
        cancelText: t('orders.cancel'),
        confirmText: t('providers_settings.yes_logout'),
        confirmColor: 'primary',
      });
      if (confirmed) {
        eventEmit('leave', data?._id);
        dispatch(logout()).then(() => {
          history.push('/login');
          setClickLogOut(false);
          dispatch({ type: MODIFIED_LOG_OUT, payload: false });
        });
      } else {
        setClickLogOut(false);
      }
    }
  }, [clickLogOut, modifiedLogOut, t, eventEmit, data, dispatch, history]);

  useEffect(() => {
    openConfirmPopup();
  }, [openConfirmPopup]);

  const isTableOrdering = isTableOrderingProvider(providers?.items);

  useEffect(() => {
    if (!location.pathname.includes('history')) {
      dispatch({
        type: ORDERS_HISTORY,
        payload: {
          data: {
            count: 0,
            orders: [],
          },
        },
      });
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (token && planId && corporateBusiness) {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        history.push(`/providers?token=${token}`);
      }
    }
  }, [token, planId, corporateBusiness]);

  useEffect(() => {
    if (currentBusiness && Object.keys(currentBusiness).length > 0) {
      if (!isTypeSingle && !isTypeBrand && isLogin) {
        history.push('/orders');
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (
        !isTypeSingle &&
        !isTypeBrand &&
        !isLogin &&
        excludePages.includes(window.location.pathname)
      ) {
        history.push('/orders');
      } else if (isTypeSingle && isLogin) {
        const redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl) {
          history.push(redirectUrl);
          return;
        }
        if (!checkPermission(all['HOME_REPORT_FILTER'])) {
          history.push('/orders');
        } else {
          history.push('/');
        }
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (isTypeSingle && !isLogin) {
        if (
          !checkPermission(all['HOME_REPORT_FILTER']) &&
          window.location.pathname === '/'
        ) {
          history.push('/orders');
        }
        if (
          !checkPermission(all['TUTORIAL_VIEW']) &&
          window.location.pathname === '/tutorials'
        ) {
          history.push('/orders');
        }
        if (
          (!checkPermission(all['APP_BUNDLE']) &&
            !window.location.search.includes('restaurantmailbox')) ||
          (!checkPermission(all['PROVIDERS_VIEW']) &&
            window.location.pathname === '/providers')
        ) {
          history.push('/orders');
        }
        if (
          !checkPermission(all['SETTINGS_WEBSITE']) &&
          window.location.pathname === '/website/coupons'
        ) {
          history.push('/orders');
        }
        if (
          !checkPermission(all['SETTINGS_WEBSITE']) &&
          window.location.pathname === '/website/main'
        ) {
          history.push('/orders');
        }
        if (
          !checkPermission(all['WEB_BUNDLE']) &&
          window.location.pathname === '/website/coupons'
        ) {
          history.push('/orders');
        }
      } else if (!isTypeSingle && isTypeBrand && isLogin) {
        history.push('/menu/overview');
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (
        !isTypeSingle &&
        isTypeBrand &&
        !isLogin &&
        excludePagesBrand.includes(window.location.pathname)
      ) {
        history.push('/menu/overview');
      }
    }
  }, [
    isTypeSingle,
    isTypeBrand,
    currentBusiness,
    isLogin,
    history,
    dispatch,
    checkPermission,
    all,
    excludePages,
    excludePagesBrand,
  ]);
  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Optional Header with Back Arrow */}
        {/* Uncomment the following block if you want to add a back arrow at the top of the Sidebar */}
        {/* 
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid #ccc',
          }}
        >
          <IconButton onClick={() => history.goBack()} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginLeft: '8px' }}>
            {t('sidebar.navigation')}
          </Typography>
        </Box>
        */}

        <Box className="sidebar-body">
          {skeletonLoading ? (
            <SkeletonSidebarList />
          ) : (
            <List
              disablePadding={true}
              sx={{
                '& .Mui-selected': {
                  backgroundColor: '#222933 !important',
                },
              }}
            >
              {isTypeSingle && (
                <Private permission="HOME_REPORT_FILTER">
                  <ListItemButton
                    selected={location.pathname === '/'}
                    component={Link}
                    to="/"
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Home')} />
                  </ListItemButton>
                </Private>
              )}

              {!isTypeBrand && (
                <ListItemButton
                  selected={location.pathname.includes('orders')}
                  onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                  component={Link}
                  to="/orders"
                >
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Orders')}>
                    {!!newOrders && (
                      <Badge pill color="danger" className="ml-auto">
                        {newOrders}
                      </Badge>
                    )}
                  </ListItemText>
                </ListItemButton>
              )}
              {checkPermission(all['SUPER']) ||
              !checkPermissionPlan(all['EXTERNAL_BUNDLE']) ? (
                <Private permission="SETTINGS_USERS">
                  <ListItemButton
                    selected={location.pathname.includes('chargeback')}
                    onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                    component={Link}
                    to="/chargeback/new"
                  >
                    <ListItemIcon>
                      <MoneyOffIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Chargeback')} />
                  </ListItemButton>
                </Private>
              ) : (
                <></>
              )}
              {!isTypeBrand && (
                <ListItemButton
                  selected={location.pathname.includes('history')}
                  onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                  component={Link}
                  to="/history"
                >
                  <ListItemIcon>
                    <HistoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Order history')}>
                    {!!newOrders && (
                      <Badge pill color="danger" className="ml-auto">
                        {newOrders}
                      </Badge>
                    )}
                  </ListItemText>
                </ListItemButton>
              )}

              <ListItemButton
                selected={location.pathname.includes('/menu')}
                component={Link}
                to="/menu/overview"
              >
                <ListItemIcon>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.name')} />
              </ListItemButton>

              {isTableOrdering && (
                <Private permission="SETTINGS_USERS">
                  <ListItemButton
                    selected={location.pathname.includes('/table-ordering')}
                    component={Link}
                    to="/table-ordering/tables"
                  >
                    <ListItemIcon>
                      <TableRestaurantIcon />
                    </ListItemIcon>
                    <ListItemText primary="Table Ordering" />
                  </ListItemButton>
                </Private>
              )}

              {!isTypeBrand && (
                <Private permission="HOME_REPORT_FILTER">
                  <ListItemButton
                    selected={location.pathname.includes('/reports')}
                    component={Link}
                    to="/reports"
                  >
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('sidebar.reports')} />
                  </ListItemButton>
                </Private>
              )}

              {isTypeSingle && (
                <PrivatePlan permission="APP_BUNDLE">
                  <Private permission="PROVIDERS_VIEW">
                    <ListItemButton
                      selected={location.pathname.includes('/providers')}
                      component={Link}
                      to="/providers"
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('sidebar.providers')} />
                    </ListItemButton>
                  </Private>
                </PrivatePlan>
              )}
              {(checkPermission(all['SUPER']) ||
                !checkPermissionPlan(all['EXTERNAL_BUNDLE'])) &&
                !isTypeBrand && (
                  <PrivatePlan permission="WEB_BUNDLE">
                    <Private permission="SETTINGS_WEBSITE">
                      <ListItemButton
                        selected={location.pathname.includes('/website')}
                        component={Link}
                        to="/website/main"
                      >
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('menu.website')} />
                      </ListItemButton>
                    </Private>
                  </PrivatePlan>
                )}

              <ListItemButton
                selected={
                  location.pathname.includes('/settings') &&
                  !location.pathname.includes('table-ordering') &&
                  !location.pathname.includes('/website') &&
                  !location.pathname.includes('/providers')
                }
                component={Link}
                to="/settings/business"
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('sidebar.settings')} />
              </ListItemButton>

              {/*<Private permission="TUTORIAL_VIEW">*/}
              {/*  <ListItemButton*/}
              {/*    selected={location.pathname.includes('/tutorials')}*/}
              {/*    component={Link}*/}
              {/*    to="/tutorials"*/}
              {/*  >*/}
              {/*    <ListItemIcon>*/}
              {/*      <BookIcon />*/}
              {/*    </ListItemIcon>*/}
              {/*    <ListItemText primary={t('sidebar.tutorials')} />*/}
              {/*  </ListItemButton>*/}
              {/*</Private>*/}

              <ListItemButton
                selected={location.pathname.includes('/help')}
                component={Link}
                to="/help"
              >
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={t('help.name')} />
              </ListItemButton>

              <ListItemButton
                selected={location.pathname.includes('/schedule')}
                component={Link}
                to="/schedule"
              >
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={t('schedule.name')} />
              </ListItemButton>
            </List>
          )}
        </Box>

        {/* Logout Section */}
        <Box className={'logout-block'} sx={{ width: '100%' }}>
          {skeletonLoading ? (
            <>
              <SkeletonWrap variant="text" height={5} width={239} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pl: 2,
                  pr: 2,
                  height: '81px',
                }}
              >
                <Box sx={{ minWidth: '56px' }}>
                  <SkeletonWrap variant="rounded" width={20} height={20} />
                </Box>
                <SkeletonWrap variant="rounded" width={151} height={24} />
              </Box>
            </>
          ) : (
            <ListItemButton
              onClick={handleLogout}
              sx={{
                py: 3,
                borderTop: `1px solid #646973`,
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.log_Out')} />
            </ListItemButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(Sidebar);
