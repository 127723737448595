import { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LinkedProviderPopover from 'components/MenuNew/LinkedProviderPopover';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';
import { duplicateCategoryReq } from 'redux/actions/Menu/menu';

const FormHeader = ({ title, handleDelete, menu, connectedProviders, id, mode, targetData, setLoading = () => {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const delateCategory = () => {
    setAnchorEl(null);
    handleDelete();
  };

  const duplicateCategory = async () => {
    try {
      handleClose();
      setLoading(true);
      await duplicateCategoryReq(id);
      history.replace(history.location.pathname);
      window.location.reload();
    } catch (err) {
      console.log(err, '>>>>>>');
    } finally {
      setLoading(false);
    }
  };

  const itemChangesCategory = () => {};

  const goBack = () => {
    history.push('/menu/categories');
  };
  const isInOverview = history?.location.pathname?.includes('/overview');
  return (
    <Box
      sx={{
        px: 4,
        py: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          {!isInOverview && <KeyboardBackspaceIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={goBack} />}
          {title || t('menu.add_new_category')}
        </Typography>
        <Private permission="MENU_EDIT">
          {mode === 'edit' && (
            <LinkedProviderPopover
              connectedProviders={connectedProviders}
              target="category"
              id={id}
              targetConnectedProviders={menu.connectedProviders}
              targetData={targetData}
            />
          )}
        </Private>
      </Box>
      <Private permission="MENU_EDIT">
        {mode === 'edit' && (
          <>
            <IconButton sx={{ ml: 1 }} disableRipple onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={delateCategory}>{t('settings.delete')}</MenuItem>
              <MenuItem disabled={mode !== 'edit'} onClick={duplicateCategory}>
                {t('menu.duplicate')}
              </MenuItem>
              <MenuItem disabled onClick={itemChangesCategory}>
                {t('menu.see_item_changes')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Private>
    </Box>
  );
};

export default FormHeader;
